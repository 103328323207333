import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { InputAdornment, Avatar } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Alert from "../Notifications/Alert";
import ReactFlagsSelect from "react-flags-select";

import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Link,
  IconButton,
} from "@mui/material";
import { Link as LinkDom } from "react-router-dom";

import {
  profileUpdate,
  verificationSend,
  updateProfilePicture,
} from "../../actions/auth";
import { useRef } from "react";
import { withTranslation } from "react-i18next";
import { errormsg } from "apihelper";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const useStyles = makeStyles((theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    hideprofile: {
      opacity: "0",
    },
    profile: {
      backgroud: theme.palette.primary,
      width: theme.spacing(10),
      height: theme.spacing(10),
      cursor: "pointer",
    },
  })
);
const Profile = ({ t, countries, user }) => {
  const classes = useStyles();
  let history = useNavigate();
  const dispatch = useDispatch();
  const [selectedCountry, setCountry] = useState({});
  const [showPassword, setShowPassword] = useState(false);


  const [formData, setFormData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    // country_id: user.innovator.country_id,
    about_me: user.innovator?.about_me,
    _method: "PUT",
  });
  let become_innovator = sessionStorage.getItem("become_innovator");
  sessionStorage.removeItem("become_innovator");
  const [isInnovator, setInnovatorFlag] = useState(become_innovator ?? false);

  const inputFile = useRef(null);

  const countryCode = () => {
    if (countries) {
      const country_code = countries.map((value, index) => {
        return value.code;
      });
      return country_code;
    }
  };

  const changeCountry = (code) => {
    const country = countries.filter((value) => {
      if (value.code == code) {
        return value.id;
      }
    });
    if (country) {
      setCountry(country[0]);
      setFormData({ ...formData, ["country_id"]: country[0].id });
    }
  };

  const initialFormData = (obj, country_id) => {
    const country = countries?.filter((value) => {
      if (value.id == country_id) {
        return value.id;
      }
    });
    if (country && country[0]) {
      setCountry(country[0]);
      setInnovatorFlag(true);
      obj.country_id = country[0].id;
    }
    setFormData(obj);
  };

  useEffect(() => {
    if (user) {
      let obj = { ...formData, ...user, about_me: user?.innovator?.about_me };
      initialFormData(obj, user?.innovator?.country_id);
    }
  }, [user, countries]);

  const updateEmailPhone = (type) => {
    history("/update-credentails", {
      state: { type: type },
    });
  };
  const verifyEmailPhone = (type) => {
    const emailForm = {
      type: type,
      email: user.email,
    };
    const phoneForm = {
      type: type,
      phone: user.phone,
      phone_code: user.phone_code,
    };
    let verificationForm = type == "email" ? emailForm : phoneForm;
    dispatch(verificationSend(verificationForm)).then((res) => {
      if (res.status) {
        history("/verify", {
          state: { formData: verificationForm, pagefrom: "verification" },
        });
      }
    });
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePhoneCode = (e) => {
    setFormData({ ...formData, ["phone_code"]: e });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (isInnovator && !user.url) {
      errormsg(t("image required error"));
      window.scroll(0, 0);
      return false;
    } else {
      dispatch(profileUpdate(formData)).then((res) => {
        if (res.status) {
          history({
            pathname: "/",
          });
        }
      });
    }
  };
  const ProfileImageBrowse = () => {
    inputFile.current.click();
  };
  const profilePictureChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    const pictureForm = new FormData();
    pictureForm.append("profile_picture", file);
    if (typeof file != undefined) {
      dispatch(updateProfilePicture(pictureForm)).then((res) => {
        if (res.status) {
          const user_state = { ...user, url: res.data.url };
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: { user: user_state },
          });
        }
      });
    }
  };
  if (!countries) return null;
  return (
    <Container maxWidth="sm" component="main">
      <Box component="div" m={8} align="center">
        <Typography variant="h5" align="center">
          {t("profile.title")}
        </Typography>
        <Alert />
        <div>
          <Avatar
            alt={user.name}
            src={user.url}
            className={classes.profile + " userprofileouter"}
            onClick={ProfileImageBrowse}
          />
          <input
            type="file"
            name="profile"
            accept="image/png, image/jpeg, image/webp,image/bmp"
            className={classes.hideprofile}
            onChange={profilePictureChange}
            ref={inputFile}
          />
          {user.profile_complete && (
            <LinkDom to="/change-password">{t("Change Password")}</LinkDom>
          )}
        </div>

        <form onSubmit={(e) => onSubmit(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="first_name"
            label={t("profile.first_name")}
            name="first_name"
            autoComplete="first_name"
            type="text"
            value={formData.first_name}
            required
            InputLabelProps={{ shrink: true }}
            onChange={(e) => onChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="last_name"
            label={t("profile.last_name")}
            name="last_name"
            autoComplete="Last Name"
            type="text"
            value={formData.last_name}
            required
            InputLabelProps={{ shrink: true }}
            onChange={(e) => onChange(e)}
          />
          {!user.profile_complete && (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              label={t("Password")}
              name="password"
              autoComplete="password"
              type={showPassword ? "text" : "password"}
              // autoFocus
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      arial-label="toggle passwor visibility"
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={t("profile.email")}
            name="email"
            autoComplete="email"
            type="email"
            value={formData.email}
            onChange={(e) => onChange(e)}
            required
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {user.is_email_verified && <CheckIcon color="primary" />}
                  {!user.is_email_verified && (
                    <Link onClick={(e) => verifyEmailPhone("email")}>
                      {t("Verify")}
                    </Link>
                  )}
                </InputAdornment>
              ),
              readOnly: user.is_email_verified ? true : false,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="phone"
            label={t("profile.phone")}
            name="phone"
            autoComplete="phone"
            type="phone"
            value={formData.phone}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {user.is_phone_verified && <CheckIcon color="primary" />}
                  {!user.is_phone_verified && user.phone ? (
                    <Link onClick={(e) => verifyEmailPhone("phone")}>
                      {t("Verify")}
                    </Link>
                  ) : (
                    <Link onClick={(e) => updateEmailPhone("phone")}>
                      {t("Update")}
                    </Link>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {isInnovator && (
            <>
              <TextField
                variant="outlined"
                id="outlined-multiline-flexible"
                label="About Me"
                name="about_me"
                multiline
                fullWidth
                rows={8}
                required
                value={formData.about_me}
                onChange={(e) => onChange(e)}
              />

              <Box component="div" m={1}>
                <ReactFlagsSelect
                  className={classes.select}
                  countries={countryCode()}
                  defaultCountry=""
                  searchable
                  searchPlaceholder="Search countries"
                  required
                  selected={selectedCountry ? selectedCountry.code : false}
                  onSelect={(code) => changeCountry(code)}
                  placeholder="Choose Country"
                  selectedSize={16}
                  optionsSize={16}
                />
              </Box>
            </>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            className="sooqiraq-btn text-white"
          >
            {t("profile.save")}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

// export default withTranslation()(Profile);
const mapStateToProps = (state) => ({
  user: state.auth.user,
  countries: state.countries.countries,
});
export default withTranslation()(connect(mapStateToProps, {})(Profile));
