import {
  Grid,
  Container,
  Box,
  ButtonGroup,
  Button,
  CircularProgress,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Divider,
  Chip,
  Backdrop,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Alert from "../Notifications/Alert";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getCompleteCart,
  removeItemFromCart,
  moveItemToWishList,
  updateProductQuantity,
  createAddress,
  openUpdateAddress,
  getAddresses,
  removeAddress,
  updateAddress,
  updateCartAddress,
} from "actions/cart";
import { withTranslation } from "react-i18next";

import Simmer from "../common/skeleton";
import {
  AddCircleOutline,
  Close,
  RemoveCircleOutline,
} from "@mui/icons-material";
import AlertBox from "components/Notifications/Alert";
import ConfirmDialog from "components/orders/ConfirmPopup";
import DeliveryMessage from "components/common/DeliveryMessage";
import Coupon from "components/checkout/coupon";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 60,
    marginBottom: 60,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  borderBox: {
    border: "1px solid #ccc",
    padding: theme.spacing(2),
    boxShadow: "none",
    marginBottom: 16,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
    paddingLeft: "5px",
  },
  noRecord: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    // alignItems:'center'
  },
  checkoutButton: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
  },
}));
const Cart = (props) => {
  const { t } = props;
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [productList, setProductList] = useState({});
  const [cartDetail, setCart] = useState({});
  const [confirm, setConfirm] = useState({});
  const [showAddressUpdate, setShowAddressUpdate] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [showAddressList, setShowAddressList] = useState(false);
  const [showAddressListBilling, setShowAddressListBilling] = useState(false);
  const handleAddressPopupOpen = (status) => {
    props.openUpdateAddress(status);
  };
  useEffect(() => {
    props.getCompleteCart();
  }, [props.getCompleteCart]);

  useEffect(() => {
    setShowAddressUpdate(props.openAddressPopup);
  }, [props.openAddressPopup]);
  useEffect(() => {
    if (props.cartDetail) {
      setProductList(props.cartDetail?.items);
      setCart(props.cartDetail);
    }
  }, [props.cartDetail]);

  const handleRemoveItem = (id) => {
    setConfirm({
      id: id,
    });
  };

  const handleQuantityChange = (qty, itm) => {
    if (itm.product?.moq && qty < parseInt(itm.product?.moq)) {
      return;
    }
    props.updateProductQuantity(itm.id, qty);
  };
  const handleMoveToWishlist = (id) => {
    props.moveItemToWishList(id);
  };

  const handleOrderClick = () => {
    history("/address", {
      state: {
        billingAddress: selectedAddress,
        shippingAddress: selectedAddress,
      },
    });
  };

  const handleVendorClick = (item) => {
    localStorage.removeItem("country");
    dispatch({ type: "SELECTED_COUNTRY", payload: false });

    setTimeout(() => {
      history(`/products/${item?.url_key}`);
    }, 10);
  };

  return (
    <>
      <Container className={classes.root} maxWidth="lg">
        {(props.cartDetailLoading || props.addressListLoading) && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" disableShrink />
          </Backdrop>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <AlertBox />
                {productList && productList.length ? (
                  <h2>
                    {t("Items")} ({cartDetail.items_count})
                  </h2>
                ) : (
                  <> </>
                )}
                {productList && productList.length ? (
                  productList.map((itm) => (
                    <Card className={classes.borderBox}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            onClick={() => handleVendorClick(itm.product)}
                            to="#"
                          >
                            <img
                              className={classes.media}
                              src={itm.product?.base_image?.medium_image_url}
                              title="Contemplative Reptile"
                              height="160"
                              width="140"
                            />
                          </Link>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <CardContent style={{ padding: 0 }}>
                            <Link
                              style={{ textDecoration: "none", color: "black" }}
                              onClick={() => handleVendorClick(itm.product)}
                              to="#"
                            >
                              <Typography variant="h4" component="h2">
                                {itm.product.name}
                              </Typography>
                            </Link>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              style={{ lineHeight: 2 }}
                            >
                              {t("Sold by")}{" "}
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/vendor/profile/${itm.product.seller.url}`}
                              >
                                {itm.product.seller.shop_title}
                              </Link>
                            </Typography>
                            <Grid
                              spacing="2"
                              style={{ display: "flex", marginTop: 10 }}
                            >
                              {Object.keys(
                                itm.additional?.attributes || {}
                              ).map((attr) => (
                                <Chip
                                  style={{ margin: 2 }}
                                  label={
                                    t(
                                      itm.additional.attributes[attr]
                                        .attribute_name
                                    ) +
                                    ": " +
                                    itm.additional.attributes[attr].option_label
                                  }
                                />
                              ))}

                              {itm.additional?.quantity && (
                                <ButtonGroup
                                  size="small"
                                  aria-label="small outlined button group"
                                  style={{ marginTop: -3, padding: 5 }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleQuantityChange(
                                        parseInt(itm.quantity - 1),
                                        itm
                                      )
                                    }
                                    className={classes.minusButton}
                                    disabled={
                                      itm.product?.moq == itm.quantity ||
                                      itm.quantity == 1
                                    }
                                  >
                                    <RemoveCircleOutline />
                                  </Button>
                                  <Button
                                    style={{ width: 40, color: "black" }}
                                    disabled
                                  >
                                    {itm?.quantity}
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleQuantityChange(
                                        parseInt(itm.quantity + 1),
                                        itm
                                      )
                                    }
                                    className={classes.plusButton}
                                  >
                                    <AddCircleOutline />
                                  </Button>
                                </ButtonGroup>
                              )}
                            </Grid>
                              <DeliveryMessage
                                full_fill_by_meairaq={
                                  itm.product.full_fill_by_meairaq
                                }
                              />
                          </CardContent>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Typography variant="h4" component="h2">
                            {itm.formated_total}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.actions}>
                          <Button
                            size="small"
                            style={{ fontWeight: 600, fontSize: 15 }}
                            color="primary"
                            onClick={() => handleMoveToWishlist(itm.id)}
                          >
                            {t("Move to wishlist")}
                          </Button>

                          <Button
                            size="small"
                            color="secondary"
                            style={{
                              fontWeight: 600,
                              fontSize: 15,
                              color: "red",
                            }}
                            onClick={() => handleRemoveItem(itm.id)}
                          >
                            {t("Remove")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={1}>
        </Grid>         */}
          <Grid
            item
            xs={12}
            md={5}
            style={{ paddingRight: 50, paddingLeft: 50 }}
          >
            <Grid container spacing={0}>
              {cartDetail &&
              cartDetail.formated_grand_total &&
              cartDetail.items_count ? (
                <Grid xs={12}>
                  <h2>
                    {t("Price Details")} ({cartDetail.items_count})
                  </h2>
                  {props.loading_order_create ? (
                    <Simmer total={1} />
                  ) : (
                    <>
                      <Card className={classes.borderBox}>
                        <Grid xs={12} container spacing={0}>
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Total Price")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.formated_sub_total}
                            </p>
                          </Grid>

                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Shipping Charges")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.selected_shipping_rate
                                ?.formated_price || "0.00"}
                            </p>
                          </Grid>
                          {cartDetail.discount > 0 ? (
                            <Grid xs={8} md={8}>
                              <p style={{ color: "grey" }}>
                                {t("Total Discount ")}:
                              </p>
                            </Grid>
                          ) : (
                            " "
                          )}
                          {cartDetail.discount > 0 ? (
                            <Grid xs={4} md={4}>
                              <p style={{ fontWeight: "600" }}>
                                {"-"}
                                {cartDetail.formated_discount}
                              </p>
                            </Grid>
                          ) : (
                            " "
                          )}
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Deduct From Wallet")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {"-"}
                              {cartDetail.formated_wallet_detected}
                            </p>
                          </Grid>

                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Grand Total")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.formated_payable_amount}
                            </p>
                          </Grid>
                        </Grid>
                        <Coupon coupon_code={cartDetail.coupon_code}/>
                      </Card>
                      <Grid xs={12} container spacing={0}>
                        <Button
                          className={classes.checkoutButton}
                          variant="contained"
                          color="primary"
                          onClick={handleOrderClick}
                        >
                          {t("Proceed to checkout")}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>

        {!(productList?.length > 0) ? (
          <Grid className={classes.noRecord}>
            <h3 style={{ textAlign: "center" }}>
              <strong> {t("Your Cart is Empty")}</strong>
            </h3>
            <Link
              to="/products"
              style={{ textAlign: "center", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                className="sooqiraq-btn"
                style={{ color: "white", textAlign: "center", marginTop: 15 }}
              >
                {t("continue shopping")}
              </Button>
            </Link>
          </Grid>
        ) : (
          <></>
        )}
        <ConfirmDialog
          onConfirm={() => {
            props.removeItemFromCart(confirm.id);
            setConfirm({});
          }}
          setOpen={() => setConfirm({})}
          open={confirm?.id}
          title={t("Are you sure ?")}
          children={`${t("You want to remove item")}, ${t(
            `This step can not be undone`
          )}`}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartDetail: state.cart.cartDetail,
  cartDetailLoading: state.cart.cartDetailLoading,
  openAddressPopup: state.cart.openAddressPopup,
  createAddressLoader: state.cart.createAddressLoader,
  addressList: state.cart.addressList,
  addressListLoading: state.cart.addressListLoading,
  loading_order_create: state.cart.loading_order_create,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getCompleteCart,
    removeItemFromCart,
    moveItemToWishList,
    updateProductQuantity,
    createAddress,
    openUpdateAddress,
    getAddresses,
    removeAddress,
    updateAddress,
    updateCartAddress,
  })(Cart)
);
