
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import LazyLoad from 'react-lazyload';

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import Footerbar from './components/Home/Footerbar';
import Header from "components/Layouts/Header/Header";
import Footer from "components/Layouts/Footer/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { loaduser } from "./actions/auth";
import { getThemeByName, getConfigData } from "../src/actions/common";
import WebRoute from "./routes";
import { Box } from "@mui/material";
import ReactPixel from "react-facebook-pixel";

const App = () => {
  let dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const themenew = getThemeByName(
    localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country")).code
      : ""
  );
  const options = {
    autoConfig: true,
    debug: false, // enable logs
  };
  const advancedMatching = { em: "taunsaini001@gmail.com" }; 

  useEffect(() => {
    if (token) dispatch(loaduser());
  }, [token]);
  useEffect(() => {
    dispatch(getConfigData());
    ReactPixel.init("561928405705388", advancedMatching, options);

  }, []);
  return (
    <div>
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themenew}>
            <CssBaseline />
            <Header />
            <Box className="webouter">
              <WebRoute />
            </Box>
            <LazyLoad height={200} offset={70}>
              <Footer />
            </LazyLoad>
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </div>
  );
};

export default App;
