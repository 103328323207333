import { Button, Divider, Grid, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import {
  Chat,
  Edit,
  Image,
  ImageAspectRatio,
  Description as DescriptionIcon,
  Store as StoreIcon,
  WhatsApp,
} from "@mui/icons-material";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tabs: {
    color: theme.palette.primary.main,
  },
  tab: {
    flexDirection: "row",
    textAlign: "left",
  },
  main: {
    padding: 20,
  },
}));
const TabItem = (props) => {
  const classes = useStyles();
  const [active, setActive] = useState(0);

  const handleTabChange = (e, value) => {
    if (value === 1) {
      window.open(`/vendor/profile/${props.sellerUrl}`, "_blank");
    } else if (value === 2) {
      document.getElementById("reviews").scrollIntoView(true);
    } else if (value === 3) {
      document.getElementById("gallery").scrollIntoView(true);
    } else {
      setActive(value);
    }
  };
  return (
    <>
      <Tabs
        className={classes.tabs}
        className="detailstab"
        value={active}
        onChange={handleTabChange}
        wrapped={true}
        aria-label="icon label tabs example"
      >
        <Tab
          className={classes.tab}
          icon={<DescriptionIcon style={{ marginRight: 4 }} />}
          label={props.t("Description")}
        />
        {props.sellerUrl && (
          <Tab
            icon={<StoreIcon style={{ marginRight: 4 }} />}
            label={props.t("Visit My Store")}
          />
        )}
        <Tab
          icon={<Edit style={{ marginRight: 4 }} />}
          label={props.t("Reviews")}
        />
        <Tab
          icon={<Image style={{ marginRight: 4 }} />}
          label={props.t("Gallery")}
        />
        {props.whatsappNo && (
          <Tab
            style={{ minWidth: 100 }}
            icon={<Chat size="small" style={{ marginRight: 4 }} />}
            label={props.t("Chat")}
          ></Tab>
        )}
      </Tabs>
      <Grid className={classes.main}>
        {active == 0 && (
          <Grid
            dangerouslySetInnerHTML=
            {{
              __html:props.description,
            }}>
          </Grid>
        )}
        {active == 3 && (
          <Grid style={{ marginLeft: "25%" }}>
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href={`https://wa.me/${props.whatsappNo || ""}`}
            >
              <Button variant="contained" style={{ color: "white" }}>
                <WhatsApp style={{ marginRight: 4 }} /> Connect with Vendor on
                WhatsApp
              </Button>
            </a>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withTranslation()(TabItem);
