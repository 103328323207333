import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getProducts, resetData } from "actions/product";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import ProductCard from "./productCard";
import { loadCSS } from "fg-loadcss";
import InfiniteScroll from "react-infinite-scroll-component";

import FormControl from "@mui/material/FormControl";
import {
  NativeSelect as Select,
  Button,
  Backdrop,
  CircularProgress,
  Box,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import ProductFilter from "./productFilter";
import Chip from "@mui/material/Chip";
import Simmer from "../common/skeleton";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { convertObjectIntoQueryString } from "utils/global";
import { Close } from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import NotFound from "components/common/NotFound";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    backgroundColor: "#f2f6f4",
    "& .MuiChip-clickable": {
      height: 28,
      margin: 4,
    },
    "& .MuiGrid-root.MuiGrid-container": {
      alignItems: "flex-start",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  productContainerRow: {
    width: "20%",
    [theme.breakpoints.down('lg')]: {
      width: "33.33%",
    },
    [theme.breakpoints.down('md')]: {
      width: "50%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  productContainer: {
    width: "25%",
    [theme.breakpoints.down('lg')]: {
      width: "33.33%",
    },
    [theme.breakpoints.down('md')]: {
      width: "50%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  filterRow: {
    margin: 8,
    marginTop: 24,
  },
  filterColumn: {
    display: "flex",
    alignItems: "center",
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    "& .fa-filter": {
      color: theme.palette.primary.main,
      fontSize: 16,
      marginRight: 8,
    },
  },
  filterText: {
    fontWeight: "bold",
  },
  filterCount: {
    color: "#868686",
    fontSize: 14,
    margin: 8,
  },
  filterBar: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    color: theme.palette.text.secondary,
  },
  filterTitle: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    lineHeight: 3,
    textTransform: "uppercase",
    marginTop: 10,
  },
  actionRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const Index = ({
  getProducts,
  resetData,
  productListAll,
  productListMetaData,
  productFilterList,
  productListLoading,
  selected_country,
  t,
}) => {
  const classes = useStyles();
  const [paginationProps, setPagination] = useState({
    current_page: 1,
    per_page: 12,
  });
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState([]);
  const match = useParams();

  let history = useNavigate();
  const [filter, setFilter] = useState("");
  const [queryParams, setQueryParams] = useState(
    match?.slug
      ? "?category_slug=" + match?.slug
      : window.location.search
  );
  const [sort, setSort] = useState("");

  useEffect(() => {
    resetData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPage(1);
      getProducts(
        {
          ...(match?.slug && { category_slug: match?.slug }),
          page: 1,
          limit: paginationProps.per_page,
        },
        filter,
        sort,
        queryParams
      );
    }, 10);
  }, [sort, queryParams]);

  useEffect(() => {
    if (match?.slug) {
      setQueryParams("?category_slug=" + match?.slug);
    }
    window.scrollTo(0, 0);
  }, [match?.slug]);

  useEffect(() => {
    window.location.search && setQueryParams(window.location.search);
  }, [window.location.search]);

  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    setPagination(productListMetaData);
  }, [productListMetaData]);

  useEffect(() => {
    setTimeout(() => {
      if (page === 1) {
        setProductList(productListAll);
      } else {
        setProductList((ev) => [...ev, ...productListAll]);
      }
    }, 500);
  }, [paginationProps]);

  const handleInfiniteScroll = () => {
    setPage(paginationProps.current_page + 1);
    getProducts(
      {
        page: paginationProps.current_page + 1,
        limit: paginationProps.per_page,
        ...(match?.slug && { category_slug: match?.slug }),
      },
      filter,
      sort,
      queryParams
    );
  };

  const handleFilterDelete = (value, type) => {
    let newFilter = filter[type] || [];
    newFilter.indexOf(value) > -1
      ? newFilter.splice(newFilter.indexOf(value), 1)
      : newFilter.push(value);

    setFilter((flt) => ({
      ...flt,
      [type]: newFilter,
    }));
    handleFilter({ ...filter, [type]: newFilter });
  };
  const handleFilter = (filter) => {
    setFilter(filter);
    let h = {};
    Object.keys(filter).map((key) => {
      h[key] = filter[key].join(",");
      if (filter[key].length == 0) delete filter[key];
    });

    let sortObj = {};
    if (sort) {
      switch (sort) {
        case "lowtohigh":
          sortObj = {
            sort: "price",
            order: "asc",
          };
          break;
        case "hightolow":
          sortObj = {
            sort: "price",
            order: "desc",
          };
          break;
        case "featured":
          sortObj = {
            sort: "featured",
          };
          break;
        case "newest":
          sortObj = {
            sort: "created_at",
            order: "desc",
          };
          break;
      }
    }

    // convertObjectIntoQueryString
    let query = `?${match?.slug ? "category_slug=" + match?.slug : ""
      }&${convertObjectIntoQueryString({
        page: paginationProps.current_page,
      })}&${convertObjectIntoQueryString(filter)}&${convertObjectIntoQueryString(
        sortObj
      )}`;
    history(window?.location?.pathname + query);
  };

  const getTextAcToCode = (value, code) => {
    let text = "";
    productFilterList &&
      productFilterList.attributes &&
      productFilterList.attributes.forEach((item) => {
        if (item.code === code) {
          item.options.forEach((itm) => {
            if (itm.id === value) {
              text = itm.label || itm.admin_name;
            }
          });
        }
      });
    return text;
  };

  return (
    <Container
      className={classes.root}
      maxWidth={false}
      disableGutters={true}
      alignItems="flex-start"
    >
      {productListLoading && page === 1 && (
        <div>
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" disableShrink />
          </Backdrop>
        </div>
      )}
      <Grid container>
        {queryParams.indexOf("search") == -1 && (
          <Grid container item xs={12} md={2} className={classes.filterBar}>
            <ProductFilter
              handleFilterCb={handleFilter}
              filterProps={filter}
              queryParams={queryParams}
              slug={match?.slug}
            />
            <Grid className={classes.actionRow}>
              <Typography className={classes.filterTitle + " filterapplied"} component="h4">
                {t("Filter Applied")}
              </Typography>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => handleFilter([])}
                className={classes.clearButton}
              >
                {t("Clear All")}
              </Button>
            </Grid>

            <Box className="appledfilters">
              <Typography component="p" className={classes.filterItem}>
                {Object.keys(filter).map((itm) =>
                  itm === "price" || itm === "limit" || itm === "search" ? (
                    <></>
                  ) : (
                    filter[itm].map((value) => (
                      <Chip
                        className={classes.filterCard}
                        label={
                          getTextAcToCode(value, itm) ||
                          (itm == "name" ? value : itm)
                        }
                        clickable
                        color="primary"
                        onDelete={() => handleFilterDelete(value, itm)}
                      />
                    ))
                  )
                )}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          md={queryParams.indexOf("search") === -1 ? 10 : 12}
        >
          <Grid container className={classes.filterRow}>
            <Grid xs="12" sm="9" className={classes.filterColumn}>
              <Grid className={classes.filter}>
                <div className={classes.filterButton}>
                  <Icon className="fa fa-filter" fontSize="small" />
                  <Typography component="p">
                    {t("Filter")}{" "}
                    {queryParams.indexOf("search") !== -1 &&
                      queryParams.split("search=")[1] && (
                        <>
                          {" "}
                          {t("for")}{" "}
                          <Chip
                            className={classes.filterCard + " productfilter"}
                            label={
                              decodeURI(queryParams.split("search=")[1])
                            }
                            clickable
                            color="primary"
                            onDelete={() => history("/products?search=")}
                          />
                        </>
                      )}
                  </Typography>

                </div>
              </Grid>
            </Grid>
            <Grid xs="12" sm="3">
              <FormControl className={classes.formControl}>
                <Select
                  native
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  name="age"
                  className={classes.sortBy}
                >
                  <option value="">{t("Sort By")}</option>
                  <option value={"newest"}>{t("Newest First")}</option>
                  <option value={"hightolow"}>{t("Price High to low")}</option>
                  <option value={"lowtohigh"}>{t("Price Low to high")}</option>
                  <option value={"featured"}>{t("Featured")}</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.filterProducts}>
            {productList.length <= 0 ? <NotFound /> :
              <InfiniteScroll
                dataLength={productList.length}
                next={handleInfiniteScroll}
                hasMore={
                  paginationProps?.last_page &&  paginationProps.last_page >= paginationProps.current_page
                   
                }
                loader={
                  <h1 style={{ textAlign: "center" }}>
                    {paginationProps?.last_page && paginationProps.last_page > paginationProps.current_page ? <CircularProgress /> : ""}
                  </h1>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    {productList.length ? (
                      <b>Yay! You have seen it all</b>
                    ) : (
                      <NotFound />
                    )}
                  </p>
                }
              >
                <Grid container spacing={2}>
                  {productList.map((itm, index) => (
                    <Grid
                      item
                      lg={queryParams.indexOf("search") === -1 ? 3 : 3}
                      md={4}
                      xs={12}
                    >
                      <ProductCard item={itm} t={t} />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            }
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  productListAll: state.product.productList,
  productListMetaData: state.product.productListMetaData,
  productFilterList: state.product.productFilterList,
  productListLoading: state.product.productListLoading,
  selected_country: state.countries.selected_country,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getProducts,
    resetData,
  })(Index)
);
