import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getVendorsProductBySlug } from "actions/vendor";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ProductCard from "components/Product/productCard";
import { Box, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      backgroundColor: "#f2f6f4",
    },
    media: {
      width: "100%",
      height: 280,
      position: "relative",
    },
    bottomBorder: {
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
      margin: "16px 0",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    cardInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    miniQty: {
      background: theme.palette.primary.main,
      color: "#fff",
      position: "absolute",
      bottom: "16px",
      left: 0,
      padding: "6px 12px",
    },
    sliderContainer: {
      //   padding: 40,
      [theme.breakpoints.down("lg")]: {
        padding: "32px 0 0 0",
      },
    },
    linkcls: {
      textDecoration: "none",
      color: "#000",
    },
    rating: {
      color: "orange",
    },
  })
);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { min: 1920 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 4000, min: 1025 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const VendorProduct = (props) => {
  const classes = useStyles();

  const [vendor_product, setvendorProduct] = useState([]);

  useEffect(() => {
    props.getVendorsProductBySlug(props.slug);
  }, [props.slug]);

  useEffect(() => {
    setvendorProduct(props.vendorProduct);
  }, [props.vendorProduct]);

  return (
    <Box component="div" style={{ marginTop: 40 }}>
      {vendor_product && vendor_product.length > 0 ? (
        <Box component="div" className={classes.sliderContainer}>
          <Typography variant="h5" component="h4" className={classes.title}>
            {props.t("Products")}
            <Link to={`/products?vendor_id=${props.id}`}>
              <span
                className="float-right"
                style={{ float: "right", color: "blue", cursor: "pointer" }}
              >
                {props.t("SEE ALL")}
              </span>
            </Link>
            <div className={classes.bottomBorder}></div>
          </Typography>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            className="carousel-container-ltr"
            autoPlaySpeed={1000}
            keyBoardControl={true}
            // customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
          >
            {vendor_product.map((product) => {
              return (
                <Grid container spacing={4} key={product}>
                  <ProductCard item={product} t={props.t} />
                </Grid>
              );
            })}
          </Carousel>
        </Box>
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  vendorProduct: state.vendor.vendorProduct,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getVendorsProductBySlug,
  })(VendorProduct)
);
