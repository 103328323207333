import React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "components/Auth/Login";
import VerifyOtp from "components/Auth/VerifyOtp";
import VerifyCredential from "components/Auth/VerifyCredential";
import Forgot from "components/Auth/Forgot";
import ResetPassword from "components/Auth/ResetPassword";
import UpdatePassword from "components/Auth/UpdatePassword";
import Profile from "components/Auth/Profile";
import GuestRoute from "./GuestRoute";
import ProductList from "components/Product";
import RandomProductList from "components/Home/RandomProduct";
import ProductDetail from "components/Product/detail";
import CmsDetail from "components/Cms/Detail";
import VendorList from "components/Vendor";
import Home from "components/Home";
import VendorDetail from "components/Vendor/detail";
import PreferredVendor from "components/Vendor/preferredVendor";
import Cart from "components/cart/index";
import Wishlist from "components/User/Wishlist";
import Checkout from "components/checkout/index.js";
import AddressCheckout from "components/checkout/address";
import Address from "components/address";
import ThankYou from "components/Payment/ThankYou.js";
import Orders from "components/orders";
import OrderDetail from "components/orders/detail";
import Invoice from "components/orders/invoice";
import Wallet from "components/wallet";
import TopSelling from "components/Home/TopSelling";
import PopularVendor from "components/Vendor/PopularVendor";
import NotFound from "components/common/NotFound";
const WebRoute = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/verify" element={<VerifyOtp />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route exact path="/products" element={<ProductList />} />
      <Route exact path="/products/:id" element={<ProductDetail />} />
      <Route
        exact
        path="/products/home/random"
        element={<RandomProductList />}
      />
      <Route exact path="/best-selling" element={<TopSelling />} />
      <Route exact path="/popular-seller" element={<PopularVendor />} />
      <Route exact path="/products/category/:slug" element={<ProductList />} />
      <Route exact path="/page/:slug" element={<CmsDetail />} />
      <Route exact path="/vendors" element={<VendorList />} />
      <Route exact path="/vendor/profile/:slug" element={<VendorDetail />} />
      <Route exact path="/Login" element={<GuestRoute />}>
        <Route path="" element={<Login />} />
      </Route>
      <Route exact path="/forgot-password" element={<GuestRoute />}>
        <Route path="" element={<Forgot />} />
      </Route>
      <Route exact path="/cart" element={<PrivateRoute />}>
        <Route path="" element={<Cart />} />
      </Route>
      <Route exact path="/checkout" element={<PrivateRoute />}>
        <Route path="" element={<Checkout />} />
      </Route>
      <Route exact path="/payment/success" element={<PrivateRoute />}>
        <Route path="" element={<ThankYou />} />
      </Route>
      <Route exact path="/address" element={<PrivateRoute />}>
        <Route path="" element={<AddressCheckout />} />
      </Route>
      <Route exact path="/my-address" element={<PrivateRoute />}>
        <Route path="" element={<Address />} />
      </Route>
      <Route exact path="/update-password" element={<PrivateRoute />}>
        <Route path="" element={<UpdatePassword />} />
      </Route>
      <Route exact path="/change-password" element={<PrivateRoute />}>
        <Route path="" element={<UpdatePassword />} />
      </Route>
      <Route exact path="/profile" element={<PrivateRoute />}>
        <Route path="" element={<Profile />} />
      </Route>
      <Route exact path="/my-preferred-vendors" element={<PrivateRoute />}>
        <Route path="" element={<PreferredVendor />} />
      </Route>
      <Route exact path="/my-wishlist" element={<PrivateRoute />}>
        <Route path="" element={<Wishlist />} />
      </Route>
      <Route exact path="/my-wallet" element={<PrivateRoute />}>
        <Route path="" element={<Wallet />} />
      </Route>
      <Route exact path="/update-credentails" element={<PrivateRoute />}>
        <Route path="" element={<VerifyCredential />} />
      </Route>
      <Route exact path="/my-orders" element={<PrivateRoute />}>
        <Route path="" element={<Orders />} />
      </Route>
      <Route exact path="/my-orders/:id" element={<PrivateRoute />}>
        <Route path="" element={<OrderDetail />} />
      </Route>
      <Route exact path="/my-orders/invoice/:id" element={<PrivateRoute />}>
        <Route path="" element={<Invoice />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default WebRoute;
