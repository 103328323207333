import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { getVendorsData } from "actions/vendor";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import VendorCard from "./vendorCard";
import { TextField, NativeSelect as Select } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { withTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import { debounce } from "helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    backgroundColor: "#f2f6f4",
    "& .MuiChip-clickable": {
      height: 28,
      margin: 4,
    },
    "& .MuiGrid-root.MuiGrid-container": {
      alignItems: "flex-start",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  productContainer: {
    width: "25%",
    [theme.breakpoints.down('lg')]: {
      width: "33.33%",
    },
    [theme.breakpoints.down('md')]: {
      width: "50%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  formControl: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1) + 2,
  },
  filterCount: {
    marginLeft: "4%",
    marginTop: theme.spacing(2),
    color: "#999999",
    fontSize: 14,
  },
}));

const Index = ({ getVendorsData, countryselected, t }) => {
  const classes = useStyles();

  const [paginationProps, setPagination] = useState({
    current_page: 1,
    per_page: 8,
  });

  const [sort, setSort] = useState("");

  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    VendorList();
  }, [getVendorsData, sort, countryselected]);

  const VendorList = (data) => {
    let page = (data && data.page) !== undefined ? data.page : paginationProps.current_page;
    let search = (data && data.search) !== undefined ? data.search : "";
    getVendorsData(
      countryselected.id,
      {
        page: page,
        limit: paginationProps.per_page,
        search: search
      },
      sort
    ).then((res) => {
      setVendors(res.data);
      setPagination(res.meta);
    });
  }
  const handlePaginationChange = (e, page) => {
    VendorList({ page: page });
  };

  const searchVendor = (e) => {
    if (e.target.value.length >= 2) {
      VendorList({ search: e.target.value });
    }

    if (e.target.value == "") {
      VendorList();
    }
  };
  const debouncedSearchVendor = useCallback(debounce((e) => {
    searchVendor(e);
  }, 1000), []);

  return (
    <Container
      className={classes.root}
      maxWidth={false}
      disableGutters={true}
      alignItems="flex-start"
    >
      <Grid container>
        <Grid container item xs={12} md={12}>
          <Grid container>
            <Grid xs="12" sm="6">
              <Grid className={classes.filter}>

                <Typography component="p" className={classes.filterCount}>
                  {t("Showing")} {paginationProps.from}-{paginationProps.to} of{" "}
                  {paginationProps.total} {t("results")}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs="12" sm="3">
              <TextField
                variant="standard"
                margin="normal"
                required
                width="50%"
                id="search-vendor"
                placeholder={t("Search vendor by name")}
                name={"search-vendor"}
                type="text"
                autoFocus={true}
                InputLabelProps={{ shrink: false }}
                onChange={(e) => debouncedSearchVendor(e)}
              />
            </Grid>
            <Grid xs="12" sm="3">
              <FormControl className={classes.formControl}>
                <Select
                  native
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  name="age"
                  className={classes.inputContainer}
                >
                  <option value="">{t("Sort By")}</option>
                  <option value={"newest"}>{t("New to old")}</option>
                  <option value="oldest">{t("Old to new")}</option>
                  <option value="hightolow">{t("Rating High to low")}</option>
                  <option value="lowtohigh">{t("Rating Low to high")}</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row">
            {vendors.length ? (
              vendors.map((itm) => (
                <>
                  <Grid className={classes.productContainer}>
                    <VendorCard item={itm} />
                  </Grid>
                </>
              ))
            ) : (
              <Grid
                style={{ marginTop: "8%", marginLeft: "40%" }}
                container
                direction="row"
              >
                {t("No Vendor Available")}
              </Grid>
            )}
          </Grid>
          <Grid className={classes.pagination}>
            <Pagination
              count={10}
              variant="outlined"
              page={paginationProps.current_page}
              onChange={handlePaginationChange}
              count={Math.ceil(
                paginationProps.total / paginationProps.per_page
              )}
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  countryselected: state.countries.selected_country,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getVendorsData,
  })(Index)
);
