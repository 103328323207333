
import {
  Grid,
  Container,
  Box,
  ButtonGroup,
  Button,
  CircularProgress,
  Typography,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AddCircleOutline,
  Favorite,
  FavoriteBorder,
  RemoveCircleOutline,
  Visibility,
  ThumbUp,
  LocalShipping,
  Share,
} from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getProductById,
  getConfigurableProductById,
  addToCart,
  wishlistProduct,
  resetData,
} from "../../actions/product";
import Alert from "../Notifications/Alert";
import TabItem from "./tabs";
import Review from "./review";
import OtherPrdSameCountry from "components/Product/detail_other_product_same_country";
import VendorOtherProducts from "components/Product/detail_vendor_other_product";
import { withTranslation } from "react-i18next";
import Gallery from "./gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { fetchYoutubeVideoDetail } from "actions/product";
import ReactPlayer from "react-player";
import Simmer from "components/common/skeleton";
import LazyLoad from "react-lazyload";
import Image from "components/common/Image";
import ReactPixel from "react-facebook-pixel";
import MetaComponent from "components/common/MetaComponent";
import { APP_ENV } from "utils/config";
import DeliveryMessage from "components/common/DeliveryMessage";
import Sharepopup from "components/common/sharepopup";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
  },
  imageBox: {
    flex: "0 0 78%",
    maxWidth: "78%",
    width: "78%",
    paddingRight: 0,
    backgroundColor: "#fbfbfb",
    height: 425,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    overflow: "hidden",
  },
  thumbnail: {
    border: "1px solid #ccc;",
    flex: "0 0 18%",
    maxWidth: "18%",
    width: "18%",
    marginLeft: "4%",
    paddingRight: 0,
    backgroundColor: "#fbfbfb",
    height: 425,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    overflow: "hidden",
  },
  detail: {},
  title: {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 15,
    paddingBottom: 0,
    lineHeight: "1.1",
  },
  description: {
    overflow: "hidden",
    fontSize: 15,
    lineHeight: "1.4",
  },
  showMore: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 15,
    lineHeight: "0.1",
  },
  showLess: {
    color: "#f00",
    fontWeight: 500,
    cursor: "pointer",
    fontSize: 15,
    lineHeight: "0.1",
  },
  minQty: {
    background: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 500,
    borderRadius: 55,
    padding: "4px 8px",
    opacity: 0.8,
  },
  productDetail: {
    border: "1px solid #ccc;",
    backgroundColor: "#fbfbfb",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    overflow: "hidden",
    padding: 10,
    marginTop: 10,
  },
  price: {},
  priceText: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  priceLine: {
    fontSize: 20,
    color: "#888",
    textDecoration: "line-through",
    marginLeft: 10,
  },
  moqTitle: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 15,
    paddingBottom: 0,
    lineHeight: "1.1",
  },
  minusButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  plusButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  button: {
    marginTop: 25,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  wishlist: {
    position: "absolute",
    transform: "translate(0%, -50%)",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "16px",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      width: "10%",
    },
  },
}));
function zoom(e) {
  var zoomer = e.currentTarget;
  window.temp = e;
  var offsetX = "";
  var offsetY = "";
  e.nativeEvent.offsetX
    ? (offsetX = e.nativeEvent.offsetX)
    : (offsetX = e.touches[0].pageX);
  e.nativeEvent.offsetY
    ? (offsetY = e.nativeEvent.offsetY)
    : (offsetX = e.touches[0].pageX);
  var x = (offsetX / zoomer.offsetWidth) * 100;
  var y = (offsetY / zoomer.offsetHeight) * 100;
  zoomer.style.backgroundPosition = x + "% " + y + "%";
}

const getItem = (itm) => {
  return (
    <>
      <figure
        class="zoom"
        onMouseMove={(event) => zoom(event)}
        style={{ backgroundImage: "url(" + itm.original + ")" }}
      >
        <Image src={itm.original} />
      </figure>
    </>

  );
};
const Detail = (props) => {
  const classes = useStyles();
  let history = useNavigate();
  const [visibleShare, setShareVisible] = useState(false);
  const [product, setProduct] = useState({});
  const [isShowMore, setShowMore] = useState(false);
  const [productQuantity, setQuantity] = useState(1);
  const [configDetail, setConfigDetail] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState({});
  const [finalAttrObj, setFinalAttrObj] = useState({});
  const [attribErrorMessage, setAttrErrorMsg] = useState(false);
  let { id } = useParams();
  const [videoObj, setVideoObj] = useState({});
  const [playing, setPlaying] = useState(true);
 
  useEffect(() => {
    props.resetData();
  }, []);
  
  useEffect(() => {
    props.getProductById(id);
    setConfigDetail(null);
    window.scrollTo(0, 0);
  }, [props.getProductById, props.selected_country, id]);
  
  useEffect(() => {
    // props.''()
    if (props?.productDetail) {
      if (props?.productDetail?.type == "configurable") {
        // call api for configurable
        props.getConfigurableProductById(props.productDetail.id);
      }
      setProduct(props.productDetail);
      if (APP_ENV == "prod") {
        ReactPixel.fbq("track", "ViewContent", {
          content_type: "product", // required property
          content_ids: product.sku, // required property, if not using 'contents'
          content_name: product.name,
        });
      }
    }

    return () => {
      props.resetData();
    };
  }, [props.productDetail, props.countries]);

  useEffect(() => {
    if (props.productConfigDetail) {
      setConfigDetail(props.productConfigDetail);
    }
  }, [props.productConfigDetail]);

  useEffect(() => {
    if (finalAttrObj && finalAttrObj.selected_configurable_option) {
      // filter variant from product.variant
      let variants = product.variants.filter(
        (item) => item.id == finalAttrObj.selected_configurable_option
      );
      if (!(variants && variants[0])) {
        setAttrErrorMsg("No variant details found for this combination");
      } else {
        // change product image, price , moq, name
        setProduct((prod) => ({
          ...prod,
          // images: variants[0].images,
          moreImages: variants[0].images,
          price: variants[0].price,
          regular_price: variants[0].regular_price,
          special_price: variants[0].special_price,
          formated_price: variants[0].formated_price,
          formated_regular_price: variants[0].formated_regular_price,
          formated_special_price: variants[0].formated_special_price,
          name: variants[0].name,
        }));
        setQuantity(parseInt(variants[0].moq || product.moq || 1));
      }
    }
  }, [finalAttrObj]);
  const createObjAttr = (itm) => {
    const temp = {};
    Object.keys(itm).map((it) => {
      temp[itm[it].attributeId] = itm[it].optionId;
    });
    return temp;
  };
  const compareObj = (itm1, itm2) => {
    if (Object.keys(itm1).length !== Object.keys(itm1).length) {
      return false;
    }
    let temp = 0;
    Object.keys(itm1).map((itm) => {
      if (itm1[itm] != itm2[itm]) {
        temp = 1;
      }
    });
    return temp == 0;
  };
  useEffect(() => {
    const config = configDetail?.index;
    if (
      configDetail?.attributes.length === Object.keys(selectedConfig).length
    ) {
      let temp = 0;
      Object.keys(config).map((itm) => {
        if (compareObj(createObjAttr(selectedConfig), config[itm])) {
          temp = itm;
        }
      });
      if (temp != 0) {
        setFinalAttrObj({
          product_id: product.id,
          selected_configurable_option: temp,
          super_attribute: config[temp],
        });
        setAttrErrorMsg("");
      } else {
        setAttrErrorMsg("No variants detail found for this combination");
      }
    }
  }, [selectedConfig]);
  useEffect(() => {
    const cb = async () => {
      if (product.video) {
        const response = await fetchYoutubeVideoDetail(product.video);
        if (response?.data) {
          setVideoObj(response.data);
        }
      } else {
        setVideoObj({});
      }
    };
    cb();
  }, [product.video]);
  const addToCartClick = () => {
    if (!localStorage.getItem("token")) {
      history("/login?redirect=" + window?.location?.pathname);
      return;
    }

    if (product.type == "configurable") {
      if (!(finalAttrObj && finalAttrObj.product_id)) {
        setAttrErrorMsg("Please select required variants");
      } else {
        props.addToCart({
          ...finalAttrObj,
          quantity: productQuantity,
        });
        setAttrErrorMsg(false);
      }
    } else {
      props.addToCart({
        product_id: product.id,
        quantity: productQuantity,
      });
      setAttrErrorMsg(false);
    }
  };

  const follow = (id) => {
    if (product.type == "configurable") {
      if (!(finalAttrObj && finalAttrObj.product_id)) {
        setAttrErrorMsg("Please select required variants");
      } else {
        props
          .wishlistProduct({
            ...finalAttrObj,
            quantity: productQuantity,
          })
          .then((res) => {
            setProduct({ ...product, is_saved: res.data });
          });
        setAttrErrorMsg(false);
      }
    } else {
      props
        .wishlistProduct({
          product_id: product.id,
          quantity: productQuantity,
        })
        .then((res) => {
          setProduct({ ...product, is_saved: res.data });
        });
      setAttrErrorMsg(false);
    }
  };

  if (!window?.location?.pathname?.split("/")[2]) {
    return null;
  }

  if (!(product && product.id)) {
    return <Container className={classes.root} maxWidth="xl"></Container>;
  }

  const getVideo = () => {
    return (
      <ReactPlayer
        url={product.video}
        playing={playing}
        onPlay={() => {
          setPlaying(true);
        }}
      />
    );
  };
  const images_for_fb = [...product.images, ...(product.moreImages || [])].map(
    (itm) => itm.url);
  let jsonld_data = {
    "@context": "http://schema.org",
    "@type": "Product",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: product?.reviews?.average_rating,
      reviewCount: product?.reviews?.total_rating,
    },
    image: product.images[0].url,
    name: product.name,
    description: product.description,
    productID: product.sku,
    url: window.location.href,
    offers: {
      "@type": "Offer",
      price: product.special_price || product.price,
      priceCurrency: localStorage.getItem("currency")
        ? JSON.parse(localStorage.getItem("currency")).label
        : "IQD",
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock",
    },
  };
  return (
    <Container className={classes.root} maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MetaComponent
                jsonLd={jsonld_data}
                title={product.meta_title??product.name }
                description={product.meta_description??product.description}
                image={product.images[0].url}
                images={product.images}
                keyword={product.meta_keywords}
              />

              <LazyLoad height={200} once>
                <ImageGallery
                  slideOnThumbnailOver={false}
                  lazyLoad={true}
                  onThumbnailClick={(index) => setPlaying(index === 0)}
                  onBeforeSlide={(index) => setPlaying(index === 0)}
                  items={[
                    ...(videoObj?.thumbnail_url
                      ? [
                          {
                            thumbnail: videoObj.thumbnail_url,
                            original: videoObj.thumbnail_url,
                            renderItem: getVideo,
                          },
                        ]
                      : []),
                    ...[...product.images, ...(product.moreImages || [])].map(
                      (itm) => ({
                        thumbnail: itm.url,
                        original: itm.url,
                        renderItem: getItem,
                        loading: "lazy",
                      })
                    ),
                  ]}
                />
              </LazyLoad>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className={classes.detail}>
                <span className={classes.title}>{product.name}</span>
                <p className={classes.description}
                  dangerouslySetInnerHTML=
                  {{
                    __html:  isShowMore ? product.description : product.short_description,
                  }}>
                 
                </p>
                <p
                  onClick={() => setShowMore(!isShowMore)}
                  className={`${
                    !isShowMore ? classes.showMore : classes.showLess
                  }`}
                >
                  {isShowMore ? props.t("View less") : props.t("View more")}{" "}
                </p>
                <Box className="detailspagettab">
                  <Box className="showbottom">
                    <Box className="productshowdelivery">
                      {product.full_fill_by_meairaq ? (
                        <Link
                          className="Deliveryttype"
                          target="_blank"
                          href="/page/delivered-by-sooqiraq"
                        >
                          <LocalShipping></LocalShipping>
                          <span>{props.t("Delivery by SOOQIRAQ")}</span>
                        </Link>
                      ) : (
                        " "
                      )}
                      {product.featured ? (
                        <Link className="Deliveryttype">
                          <ThumbUp></ThumbUp>
                          <span>{props.t("Featured")}</span>
                        </Link>
                      ) : (
                        " "
                      )}
                      <Link
                        onClick={() => setShareVisible(!visibleShare)}
                        className="Deliveryttype"
                      >
                        <Share
                          className={classes.iconShare}
                          fontSize="medium"
                        />
                      </Link>
                    </Box>
                  </Box>
                  <Box className="shareiconup">
                  {visibleShare && (
                    <Sharepopup
                    setOpen={() => setShareVisible(false)}
                    open={visibleShare}
                    title={product.name}
                    description={product.description}
                    picture={product.images[0].url}
                    url={window.location.href}
                    
                  />
                  )}
                  </Box>
                </Box>
                <Grid className={classes.productDetail}>
                  <Grid container direction="row">
                    <Grid className={classes.price}>
                      <span
                        style={{
                          fontSize: 20,
                          color: "black",
                          fontWeight: 700,
                        }}
                      >
                        {props.t("Price")}:{" "}
                      </span>
                      <span className={classes.priceText}>
                        {product.formated_special_price ||
                          product.formated_price}
                      </span>
                      {product.formated_special_price && (
                        <span className={classes.priceLine}>
                          {product.formated_regular_price}
                        </span>
                      )}
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      position="relative"
                      onClick={follow}
                    >
                      {props.user && (
                        <>
                          <Box
                            component="div"
                            className={classes.wishlist + " favwishlist"}
                          >
                            {product.is_saved ? (
                              <Favorite color="primary" fontSize="large" />
                            ) : (
                              <FavoriteBorder
                                color="primary"
                                fontSize="large"
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid style={{ display: "flex", marginTop: 14 }}>
                    <Grid>
                      <span>{props.t("Quantity")}</span>
                      <br></br>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                        style={{ marginTop: 2 }}
                      >
                        <Button
                          onClick={() =>
                            setQuantity(parseInt(productQuantity) - 1)
                          }
                          className={classes.minusButton}
                          className="sooqiraq-btn"
                          disabled={
                            productQuantity == 1 ||
                            productQuantity == product.moq
                          }
                        >
                          <RemoveCircleOutline />
                        </Button>
                        <Button style={{ width: 80, color: "black" }} disabled>
                          {productQuantity}
                        </Button>
                        <Button
                          onClick={() =>
                            setQuantity(parseInt(productQuantity) + 1)
                          }
                          className={classes.plusButton}
                          className="sooqiraq-btn text-white"
                        >
                          <AddCircleOutline />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid style={{ marginLeft: 10 }}>
                      <span>{props.t("Price")}</span>
                      <br></br>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                        style={{ marginTop: 2 }}
                      >
                        <Button
                          size="small"
                          style={{ width: 100, color: "black" }}
                          disabled
                        >
                          {" "}
                          {parseFloat(
                            parseFloat(product.price) *
                              parseInt(productQuantity)
                          ).toFixed(2)}
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                  {configDetail && (
                    <Grid>
                      {configDetail?.attributes.map((itm) => (
                        <Grid>
                          <h4>Choose {itm.label}</h4>
                          <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                            {itm.options.map((it) => (
                              <Button
                                className="sooqiraq-btn"
                                style={{
                                  margin: 3,
                                  color: "#fff",
                                }}
                                onClick={() =>
                                  setSelectedConfig({
                                    ...selectedConfig,
                                    [itm.code]: {
                                      attributeId: itm.id,
                                      optionId: it.id,
                                      products: it.products,
                                    },
                                  })
                                }
                                variant={
                                  selectedConfig[itm.code]
                                    ? selectedConfig[itm.code].optionId ===
                                      it.id
                                      ? "contained"
                                      : "outlined"
                                    : "outlined"
                                }
                                size="small"
                              >
                                {it.label}
                              </Button>
                            ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  <Grid className="addcarticonouter">
                    {attribErrorMessage && (
                      <>
                        <Grid
                          style={{ color: "red", marginTop: 10, fontSize: 14 }}
                        >
                          {attribErrorMessage}
                        </Grid>
                      </>
                    )}
                    {!props.productAddingInCart ? (
                      <Button
                        onClick={addToCartClick}
                        variant="contained"
                        className={classes.button}
                        color="primary"
                      >
                        {props.t("Add To Cart")}
                      </Button>
                    ) : (
                      <CircularProgress style={{ marginTop: 25 }} />
                    )}
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      onClick={follow}
                      className="showitemcount"
                    >
                      <Box component="div" className={classes.minQty}>
                        <Box className="radiousbox001">
                          <Typography component="span">
                            <Visibility
                              fontSize="medium"
                              style={{ paddingTop: 8 }}
                            />{" "}
                          </Typography>
                          <Typography
                            component="span"
                            style={{ fontSize: 14, fontWeight: 600 }}
                          >
                            {product.view_count}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <br></br>
                  <DeliveryMessage
                    full_fill_by_meairaq={product.full_fill_by_meairaq}
                  />
                </Grid>
              </Grid>
              <Alert />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <TabItem
                description={product.description}
                sellerUrl={product?.seller?.url}
                whatsappNo={product?.seller?.whatsapp_no}
              />
            </Grid>
            <Grid item xs={12} className={classes.main}>
              <Review
                t={props.t}
                ratingObj={product?.reviews || {}}
                slug={product?.id}
              />
            </Grid>
            <Grid item xs={12} className={classes.main}>
              <LazyLoad
                height={200}
                once
                offset={50}
                placeholder={<Simmer total={6} />}
              >
                <Gallery
                  t={props.t}
                  images={[...product.images, ...(product.moreImages || [])]}
                />
              </LazyLoad>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <Grid container>
            <VendorOtherProducts product={product} /> 
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <LazyLoad height={200} offset={50} placeholder={<Simmer total={6} />}>
            <OtherPrdSameCountry product={product} />
          </LazyLoad>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  productDetail: state.product.productDetail,
  productDetailError: state.product.productDetailError,
  cartDetailError: state.product.cartDetailError,
  cartDetail: state.product.cartDetail,
  productAddingInCart: state.product.productAddingInCart,
  productConfigDetail: state.product.productConfigDetail,
  selected_country: state.countries.selected_country,
  countries: state.countries.countries,
  user: state.auth.user,
});
const mapDispatchToProps = {
  getProductById,
  addToCart,
  getConfigurableProductById,
  wishlistProduct,
  resetData,
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Detail)
);
