import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getProducts } from "actions/product";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Box, Typography, Grid } from "@mui/material";
import ProductCard from "components/Product/productCard";
import ViewAll from "components/Home/ViewAll";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      backgroundColor: "#f2f6f4",
    },
    media: {
      width: "100%",
      height: 280,
      position: "relative",
    },
    miniQty: {
      background: theme.palette.primary.main,
      color: "#fff",
      position: "absolute",
      bottom: "16px",
      left: 0,
      padding: "6px 12px",
    },
    bottomBorder: {
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
      margin: "16px 0",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    sliderContainer: {
      padding: 40,
      [theme.breakpoints.down("lg")]: {
        padding: "32px 0 0 0",
      },
    },
    linkcls: {
      textDecoration: "none",
      color: "#000",
    },
    rating: {
      color: "orange",
    },
  })
);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { min: 1920 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 4000, min: 1025 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const NewArrival = ({ getProducts, productList, countryselected, t }) => {
  const classes = useStyles();

  useEffect(() => {
    getProducts({
      limit: 10,
      country_id: countryselected.id ? countryselected.id : 0,
      sort: "created_at",
    });
  }, [countryselected]);

  return (
    <Box component="div" m={1}>
      {productList.length > 0 ? (
        <Box component="div" m={1} className={classes.sliderContainer}>
          <Typography variant="h3" component="h3" className={classes.title}>
            {t("New Arrivals")}
            <div className={classes.bottomBorder}></div>
          </Typography>

          <Carousel
            ssr
            swipeable={false}
            style={{ direction: "ltr" }}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            // customTransition=".5"
            transitionDuration={500}
            className="carousel-container-ltr"
            containerClass="carousel-container"
          >
            {productList.map((product) => {
              return (
                <Grid
                  container
                  spacing={4}
                  className="fixsizeitems"
                  key={product}
                >
                  <ProductCard item={product} t={t} />
                </Grid>
              );
            })}
            <ViewAll
              href="/products?search=&sort=created_at&order=desc"
              t={t}
            />
          </Carousel>
        </Box>
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  countryselected: state.countries.selected_country,
});
export default connect(mapStateToProps, {
  getProducts,
})(NewArrival);
