import React, { useEffect, Fragment } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { getBanner } from "actions/banner";
import { connect } from "react-redux";
import Simmer from "components/common/skeleton";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
    },
    sliders: {
      direction: "ltr",
      height: 330,
      [theme.breakpoints.down('lg')]: {
        height: "initial",
      },
    },
  })
);

const Bannerslider = ({
  main_banner,
  selected_country,
  getBanner,
  loading,
}) => {
  const classes = useStyles();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    getBanner();
  }, []);

  return (
    <div>
      <Container>
        {loading ? (
          <Simmer total={6} />
        ) : (
          <Carousel
            arrows={false}
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            className={classes.sliders}
          >
            {main_banner.map((item, i) => (
              <Fragment key={i}>
                {item.action ? (
                  <Link to={item.action}>
                    <img
                      src={item.image_url + "?tr=w-1440,h-422,cm-pad_resize"}
                      key={i}
                      width="100%"
                    />
                  </Link>
                ) : (
                  <img
                    src={item.image_url + "?tr=w-1440,h-422,cm-pad_resize"}
                    key={i}
                    width="100%"
                  />
                )}
              </Fragment>
            ))}
          </Carousel>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  main_banner: state.banner.list.main_banner,
  selected_country: state.countries.selected_country,
  loading: state.banner.Loading,
});
export default connect(mapStateToProps, {
  getBanner,
})(Bannerslider);
