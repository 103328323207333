import React from 'react';
import MetaTags from 'react-meta-tags';
import JsonLd from './Jsonld';
const MetaComponent = (props) => {
    return (
        <div className="wrapper" >
            <MetaTags id="sooq-tag">
                <title>{props.title}</title>
                <meta property="og:type" content="website" />
                <meta name="description" content={props.description} />
                <meta name="og:description" content={props.description} />
                <meta property="og:title" content={props.title} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={props.image || ""} />
                <meta property="og:site_name" content={"Sooqiraq"} />
                <meta name="keywords" content={props.keyword || ""}/>

                {
                    props.jsonLd &&
                    <JsonLd data={props.jsonLd} />
                }
                <link rel="image_src" href={props.image || ""} /> 
                {props.images && props.image.length > 1 ? (
                    props.images.map((item) =>{
                        <meta property="og:image" content={item.url || ""} />
                    })
                ) : ( <meta property="og:image" content={props.image || ""} />)}
            </MetaTags>
        </div>
    );
}
export default MetaComponent;
