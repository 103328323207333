import React, { Fragment } from "react";
import clsx from "clsx";
import "react-multi-carousel/lib/styles.css";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Typography, Button } from "@mui/material";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 8,
      boxShadow: "none",
      border: "1px solid #efefef",
    },
    becomeSellerSection: {
      position: "relative",
      [theme.breakpoints.down('lg')]: {
        padding: "32px 0 0 0",
      },
    },
    sellerBox: {
      background: "rgb(255 255 255 / 50%)",
      position: "absolute",
      top: "50%",
      left: "50%",
      padding: "60px",
      transform: "translate(30%, -40%)",
      [theme.breakpoints.down('md')]: {
        padding: "16px",
        transform: "translate(-50%, -50%)",
      },
      [theme.breakpoints.down('sm')]: {
        padding: "16px",
        width: "80%",
        transform: "translate(-50%, -42%)",
      },
    },
    heading: {
      fontSize: "24px !important",
      fontWeight: "bold",
      [theme.breakpoints.down('lg')]: {
        fontSize: 24,
      },
    },
    topBorderbutton: {
      borderTop: "5px solid #000",
      marginTop: "25px",
      paddingTop: "8px",
      fontSize: "24px",
      fontWeight: "600",
      textTransform: "none",
      [theme.breakpoints.down('lg')]: {
        fontSize: 16,
        marginTop: 8,
      },
    },
    sellerBg: {
      [theme.breakpoints.down('lg')]: {
        width: "100%",
      },
    },
  })
);

const BecomeSeller = ({ loading, become_seller, t }) => {
  const classes = useStyles();

  return (
    <Box component="div" className="bigsellerImg" m={1}>
      <Box component="div" className={classes.becomeSellerSection}>
        {become_seller.map((item, i) => (
          <Fragment key={i}>
            {item.action ? (
              <Link to={item.action} key={i}>
                <img
                  src={item.image_url}
                  key={i}
                  className={classes.sellerBg}
                />
              </Link>
            ) : (
              <img src={item.image_url} key={i} className={classes.sellerBg} />
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  become_seller: state.banner.list.become_seller,
  selected_country: state.countries.selected_country,
  loading: state.banner.Loading,
});
export default connect(mapStateToProps)(BecomeSeller);
