import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getProducts } from "actions/product";

import { Grid, Box } from "@mui/material";
import {makeStyles} from "@mui/styles"; 
import "react-multi-carousel/lib/styles.css";
import ProductCard from "components/Product/productCard";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
}));

const VendorOtherProduct = (props) => {
  const classes = useStyles();

  const [vendorProduct, setvendorProduct] = useState([]);

  useEffect(() => {
    props
      .getProducts({
        limit: 5,
        country_id: props.countryselected?.id ? props.countryselected?.id : 0,
        similar: 1,
        vendor_id: props.product.seller?.id,
      })
      .then((res) => {
        setvendorProduct(res);
      });
  }, [props.product.id]);

  return (
    <Grid item xs={12} md={12} className="sidebarproductright">
      <Box width={1} mb={2}>
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          className={classes.title}
          color="primary"
        >
          {props.t("Vendor’s other Products ")}
        </Typography>
      </Box>
      {vendorProduct.map((product) => {
        return <ProductCard item={product} mediacss="media1" t={props.t} />;
      })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  countryselected: state.countries.selected_country,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getProducts,
  })(React.memo(VendorOtherProduct))
);
